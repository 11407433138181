import { render, staticRenderFns } from "./IncrementForm.vue?vue&type=template&id=632c5d35&scoped=true&"
import script from "./IncrementForm.vue?vue&type=script&lang=ts&"
export * from "./IncrementForm.vue?vue&type=script&lang=ts&"
import style0 from "./IncrementForm.vue?vue&type=style&index=0&id=632c5d35&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632c5d35",
  null
  
)

export default component.exports