import { render, staticRenderFns } from "./GroupLobbyUser.vue?vue&type=template&id=3acca320&scoped=true&"
import script from "./GroupLobbyUser.vue?vue&type=script&lang=ts&"
export * from "./GroupLobbyUser.vue?vue&type=script&lang=ts&"
import style0 from "./GroupLobbyUser.vue?vue&type=style&index=0&id=3acca320&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3acca320",
  null
  
)

export default component.exports