

















































































import BouncingLoader from "@/components/BouncingLoader.vue";
import Button from "@/components/Button.vue";
import ToggleBlock from "@/components/ToggleBlock.vue";
import Store from "@/store/Store";
import IRCClient from "@/twitch/IRCClient";
import TwitchUtils from "@/twitch/TwitchUtils";
import Config from "@/utils/Config";
import SpotifyAPI from "@/utils/SpotifyAPI";
import Utils from "@/utils/Utils";
import gsap from "gsap";
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{
		Button,
		ToggleBlock,
		BouncingLoader,
	}
})
export default class TwitchAuth extends Vue {

	@Prop({default:null})
	public twitchOAToken:string;
	@Prop({default:null})
	public spotifyOAToken:string;

	public loading:boolean = false;
	public checkingToken:boolean = false;
	public showForm:boolean = false;
	public error:boolean = false;
	public errorIRC:boolean = false;
	public loggedIn:boolean = false;
	public needSpotifyAuth:boolean = false;
	public spotifyExpired:boolean = false;
	public token:string = null;
	public urlOBS:string = null;

	public get authUrl():string {
		Store.set("redirect", document.location.origin+this.$router.resolve({name:'twitch/auth'}).href);
		return SpotifyAPI.instance.getAuthUrl();
	}

	public get twitchExtUrl():string { return Config.TWITCH_EXT_URL; }

	public async mounted():Promise<void> {
		let token = this.$store.state.twitchOAuthToken;
		if(this.twitchOAToken) {
			token = this.twitchOAToken;
		}
		if(token) {
			this.loading = true;
			this.token = token;
			let success = await this.submitToken();
			if(!success) {
				this.loading = false;
				this.error = false;
				this.errorIRC = false;
			}
		}
	}

	public beforeDestroy():void {
		
	}

	public async submitToken():Promise<boolean> {
		this.error = false;
		this.errorIRC = false;
		this.checkingToken = true;
		this.token = this.token.replace("oauth:", "");
		let json = await TwitchUtils.validateToken(this.token);
		if(!json) {
			this.error = true;
		}else{
			let twitchLogin = json.login;
			this.loading = true;
			this.$store.dispatch("setTwitchOAuthToken", this.token);
			this.$store.dispatch("setTwitchLogin", twitchLogin);
			let res;
			try {
				res = await IRCClient.instance.initialize(twitchLogin, this.token);
			}catch(error) {
				console.log("FAILED !");
				this.errorIRC = true;
				this.loading = false;
				this.checkingToken = false;
				return;
			}
			// console.log("Connected to IRC !", res);
			this.loggedIn = true;
			this.loading = false;
			if(this.twitchOAToken) {
				//Test if spotify token is valid, this condition is true for
				//OBS route as it contains all the necessary tokens
				try {
					await SpotifyAPI.instance.call("v1/me", null, false);
				}catch(error) {
					this.spotifyExpired = true;
					this.needSpotifyAuth = true;
					return;
				}
				let route = {
					name:"twitch/obs/play"
				}
				this.$router.push(route);
			}else if(!SpotifyAPI.instance.hasAccessToken){
				this.spotifyExpired = false;
				this.needSpotifyAuth = true;
			}else{
				// let route = {name:'twitch/auth', params:{twitchOAToken:this.token, spotifyOAToken:this.$store.state.accessToken}};
				// this.urlOBS = document.location.origin+this.$router.resolve(route).href;
				let route = {name:'twitchobs/viewer', params:{twitchLogin:twitchLogin}};
				this.urlOBS = document.location.origin+this.$router.resolve(route).href;
			}
			return true;
		}
		this.checkingToken = false;
		return false;
	}

	public clickGenerate():void {
		setTimeout(_=> {
			this.showForm = true;
		}, 1000)
	}

	public selectText(e:Event):void {
		let div = <HTMLDivElement>e.target;
		var selection = window.getSelection();
		var range = document.createRange();
		range.selectNodeContents(div);
		selection.removeAllRanges();
		selection.addRange(range);

	}

	public copyURL():void {
		Utils.copyToClipboard(this.urlOBS);
		gsap.set(this.$refs.url, {filter:"brightness(100%)"})
		gsap.from(this.$refs.url, {duration:.5, filter:"brightness(200%)"})
	}

}
